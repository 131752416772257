<template>
  <b-modal
    id="modal-editor"
    no-close-on-backdrop
    size="lg"
    centered
    no-close-on-esc
    @shown="shownEvent"
    busy
  >
    <div class="editor-container" style="height:80vh;">
      <PinturaEditor
        ref="pintura"
        :src="src"
        :locale="localeprops"
        :enableButtonClose="true"
        :cropEnableButtonFlipVertical="true"
        :cropEnableSelectPreset="false"
        :cropEnableImageSelection="crop_enabled"
        :imageCropAspectRatio="aspect_ratio"
        :imageCropLimitToImage="crop_limit"
        :cropEnableButtonRotateRight="true"
        :imageTargetSize="targetSize"
        :imageWriter="editorProps.imageWriter"
        :imageReader="editorProps.imageReader"
        v-if="editorOpen"
        @pintura:load="setLightTheme"
        @pintura:process="handlePinturaConfirm"
        @pintura:close="handlePinturaCancel"
      ></PinturaEditor>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { PinturaEditor } from "../libs/pintura/vue-pintura";
import "../libs/pintura/pintura/pintura.css";
import {
  //EDITOR
  createDefaultImageReader,
  createDefaultImageWriter,
  locale_en_gb,

  //PLUGINS
  setPlugins,
  plugin_crop,
  plugin_crop_defaults,
  plugin_crop_locale_en_gb,
} from "../libs/pintura/pintura/pintura";
setPlugins(plugin_crop);

export default {
  props: ["image", "type", "width", "height"],
  name: "modal-editor",
  components: {
    PinturaEditor,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      editorOpen: false,
      mylocale: null,
      aspect_ratio: null,
      crop_limit: true,
      targetSize: {
        width: null,
        height: null,
      },
      editorProps: {
        imageWriter: createDefaultImageWriter(),
        imageReader: createDefaultImageReader(),
        ...plugin_crop_defaults,
      },
      localeprops: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
      },
      crop_enabled: false,
      src: null,
    };
  },
  mounted() {
    this.editorOpen = true;
  },
  methods: {
    shownEvent() {
      let header = document.querySelector("#modal-editor .modal-header");
      let footer = document.querySelector("#modal-editor .modal-footer");
      header.style.display = "none";
      footer.style.display = "none";
      //Traduzione editor
      this.translateEditor();
      //Imposto risoluzione crop
      this.setCropPreset();
      //Imposto il size dell'immagine
      this.setTargetSize();
      this.src = this.image;
    },
    translateEditor() {
      this.localeprops.cropLabelButtonFlipHorizontal = this.$t(
        "editor.label_flip_horizontal"
      );
      this.localeprops.cropLabelButtonFlipVertical = this.$t(
        "editor.label_flip_vertical"
      );
      this.localeprops.cropLabelButtonRotateLeft = this.$t(
        "editor.label_rotate_left"
      );
      this.localeprops.cropLabelButtonRotateRight = this.$t(
        "editor.label_rotate_right"
      );
      this.localeprops.cropLabelTabRotation = this.$t("editor.label_rotation");
      this.localeprops.labelButtonExport = this.$t("editor.label_export");
      this.localeprops.statusLabelLoadImage = (state) => {
        if (!state || !state.task) return "";
        if (state.error) return state.error.code === "" ? "" : "";
        if (state.task === "blob-to-bitmap") return "";
        return "";
      };
      this.localeprops.statusLabelProcessImage = (state) => {
        if (!state || !state.task) return undefined;
        if (state.task === "store") {
          if (state.error) return "";
          return "";
        }
        if (state.error) return "";
        return "";
      };
    },
    setCropPreset() {
      this.aspect_ratio = (this.width / this.height).toFixed(3);
      if (
        this.type == "logo_media_press" ||
        this.type == "banner_conference" ||
        this.type == "logo_stand" ||
        this.type == "logo_floor" ||
        this.type == "contacts" ||
        this.type == "logo_point" ||
        this.type == "totem_logo" ||
        this.type == "conference_logo"
      ) {
        this.crop_limit = false;
      }
    },
    setTargetSize() {
      this.targetSize.width = this.width;
      this.targetSize.height = this.height;
    },
    setLightTheme() {
      let editor = document.querySelector(".pintura-editor");
      //pintura non supporta il background totalmente bianco
      editor.style.backgroundColor = "#ffffffe6";
      if (this.type == "logo_floor") {
        editor.style.backgroundColor = "#047c73";
      }
    },
    handlePinturaConfirm(output) {
      this.savePhoto(output.dest);
    },
    savePhoto(image) {
      let imageUrl = URL.createObjectURL(image);
      this.$emit("onSave", this.type, imageUrl, image);
      this.$bvModal.hide("modal-editor");
    },
    handlePinturaCancel() {
      this.$bvModal.hide("modal-editor");
      this.$emit("close", this.type);
    },
  },
};
</script>
<style scoped>
.pintura-editor {
  --color-foreground: 0, 0, 0;
}
</style>
